import Offer, { ModRequest } from "./models/Offer";
import Syndication from "./models/Syndication";
import { Header } from "../views/dashboard/components/List";

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
  addressSince: Date;
}

export interface BlackListStatus {
  status: "white" | "grey" | "black";
  setAt: Date;
}

export interface CafFramework {
  product: string;
  commissionRate: number;
  applicationFee: number;
  renewalCommissionRate: number;
  renewalApplicationFee: number;
}

export interface Config {
  user: User;
  products: Product[];
  fixedListTemplates: ListTemplate[];
  notifications: Notification[];
  daysPerMonth: number;
  weeksPerMonth: number;
  techFee: number;
  minPrincipal: number;
  houseOriginationFee: number;
  vb: number;
  repCommissionDiscount: {
    dealRatio: number;
    renewalDiscount: number;
    grossDiscount: number;
  }[];
  repCommissionDiscountByMtdVolume: {
    volGte: number;
    discount: number;
  }[];
  repsVolMtd?: RepVolMtd[];
  repFlatCommission?: { rep: string; product: string; commission: number }[];
  stipLabels: { name: string; internal?: boolean }[];
  entityTags: {
    name: string;
    color: string;
    icon: string;
    type?: "business" | "iso" | "syndicator";
  }[];
  entityDocLabels: {
    name: string;
    color: string;
    icon: string;
    type?: "business" | "iso" | "syndicator";
    required?: boolean;
  }[];
  appTags: {
    name: string;
    color: string;
  }[];
  usStates: { [abbrev: string]: { name: string; sos: string } };
  mcaContractRules: {
    paperClass: string;
    states: string[];
    maxPrincipal: number;
  }[];
  accountConditionCode: Record<string, string[]>;
  ecoaCodes?: Record<string, string>;
  accountPurposeTypeCode?: Record<string, string[]>;
  riskModelDesc: { [schema: string]: { [code: string]: string } };
  defaultSyndicationAccount: {
    plug: {
      syndicator: string;
      account: string;
    };
    funds: {
      syndicator: string;
      account: string;
    };
  };
  declineReasons?: DeclineReason[];
  lenders?: { name: string; id: string; code: string; isServicing: boolean }[];
  customerServiceTaskTemplates?: CustomerServiceTaskTemplate[];
  emailNotifyTemplates?: EmailNotifyTemplate[];
  postFundFeeTypes?: { type: FeeType; amount?: number; preNotes: string }[];
  defaultCounterSigner?: { name: string; email: string };
  peerReviewItems?: string[];
  legalPlacementFirms?: { name: string }[];
  ipUsersRules?: { users: string[]; ipRules: string[] }[];
  privacyPolicy?: { title: string; content: string };
  termOfUse?: { title: string; content: string };
  modelFieldsVisibility?: {
    model: "application" | "business" | "iso" | "syndicator" | "payments";
    fields: string[];
    roles: { role: Role; isManager?: boolean }[];
    controlLevel: FieldVisibilityControlLevel;
  }[];
  applicationHeaders: Header[];
  isoHeaders: Header[];
  businessHeaders: Header[];
  syndicatorHeaders: Header[];
  userHeaders: Header[];
  paymentHeaders: Header[];
  customerServiceTaskHeaders: Header[];
  uploadActivityHeaders: Header[];
  numbersOfDaysBeforeExpiring?: {
    submissions: number;
    approval: number;
    contractRequests: number;
    signedContract: number;
  };
}

export enum FieldVisibilityControlLevel {
  FULL_ACCESS,
  VIEW__NO_EXPORT,
  HIDE__NO_EXPORT,
}

export interface Ownership {
  user: Partial<User> | null;
  percentage: number;
  creditProfile?: string;
}

export interface Dba {
  dba: string;
  ownershipDate?: string;
  currentBizPhysicalAddress?: Address;
  currentBizMailingAddress?: Address;
}

export enum AppStatus {
  SUBMITTED,
  DECISIONED,
  FUNDED,
  CLOSED,
  LEGAL,
}

export enum AppCondition {
  NEW = "S1",
  IN_INTAKE = "S2",
  IN_INTAKE__MISSING_INFO = "S3",
  IN_UW = "S4",
  DECLINED = "D0",
  DECISIONING = "D30",
  APPROVED = "D1",
  APPROVED__MIXED = "D3",
  // MISSING_INFO = "D4",
  DECLINED_IN_DECISION = "D5",
  DECLINED__WAITING_FOR_BRANCH = "D6",
  OFFER_SENT = "D20",
  DECLINE_SENT = "D21",
  OFFER_EXPIRED = "D22",
  CONTRACT_EXPIRED = "D91",
  REVISION_REQUESTED = "D7",
  CONTRACT_REQUESTED_REP_REVIEW = "D8",
  REJECTED_IN_DOC_REQUEST = "D9",
  CONTRACT_REQUESTED = "D12",
  CONTRACT_OUT = "D10",
  CONTRACT_IN = "D11",
  FINAL_REVIEWING = "D13",
  READY_FOR_FUNDING = "D14",
  READY_FOR_FUNDING__PENDING_INFO = "D15",
  REJECTED_AFTER_CONTRACT = "D16",
  READY_FOR_FUNDING__PEER_REVIEW = "D17",
  PERFORMING__FIRST_30_DAYS = "F1",
  PERFORMING = "F2",
  WRITTEN_OFF__PAYING = "F3",
  EARLY_WARNING = "F4",
  COLLECTIONS = "F5",
  RESTRUCTURED__NON_ACC = "F6",
  RESTRUCTURED__ACC = "F7",
  PERFORMING__WAS_COLLECTION = "F8",
  PERFORMING__WAS_RESTRUCTURED = "F9",
  DISASTER_RELIEF = "F10",
  PERFORMING__WAS_DEFAULTED = "F11",
  DEFAULT = "F12",
  COLLECTIONS__WAS_DEFAULTED = "F13",
  RESTRUCTURED__ACC__WAS_DEFAULTED = "F14",
  RESTRUCTURED__NON_ACC__WAS_DEFAULTED = "F15",
  LEGAL__PENDING_ASSIGNMENT = "L0",
  LEGAL__RESTRUCTURED = "L1",
  LEGAL__DEFAULTED = "L2",
  PAID_IN_FULL = "C1",
  PAID_EPO = "C2",
  WRITTEN_OFF = "C3",
  SETTLEMENT = "C4",
  RETURNED = "C5",
  IGNORE = "C6",
  SOLD = "C7",
}

export enum AppAction {
  TO_INTAKE = "to-intake",
  TO_INTAKE__MISSING_INFO = "to-intake--missing-info",
  TO_NEW = "to-new",
  IDENTITY_CHECK = "identity-check",
  XPN_PULL = "xpn-pull",
  XPN_FAIL = "xpn-fail",
  XPN_PASS = "xpn-pass",
  SYSTEM_RULE_CHECK = "system-rule-check",
  OCR_CHECK = "ocr-check",
  OCR_PULL = "ocr-pull",
  OCR_PASS = "ocr-pass",
  OCR_FAIL = "ocr-fail",
  DECLINE = "decline",
  TO_UW = "to-uw",
  TO_UW_PIPELINE = "to-uw-pipeline",
  SEND_TO_ISO = "send-to-iso",
  CONTRACT_READY = "contract-ready",
  CONTRACT_PENDING_INFO = "contract-pending-info",
  CONTRACT_RESEARCH = "contract-research",
  REQUEST_NEW_CONTRACT = "request-new-contract",
  REP_REVIEW = "rep-review",
  BRING_BACK = "bring-back",
  CONTRACT_OUT = "contract-out",
  CONTRACT_IN = "contract-in",
  READY_FOR_FUNDING = "ready-for-funding",
  SEND_BACK_TO_PEER_REVIEW = "send-back-to-peer-review",
  ACTIVATION = "activation",
  SET_ACCRUAL_STATE = "set-accrual-state",
  TO_EARLY_WARNING = "to-early-warning",
  TO_PERFORMING = "to-performing",
  TO_WRITTEN_OFF = "to-written-off",
  TO_COLLECTIONS = "to-collections",
  TO_DISASTER_RELIEF = "to-disaster-relief",
  FROM_DISASTER_RELIEF = "from-disaster-relief",
  TO_DEFAULT = "to-default",
  RESTRUCTURE = "restructure",
  TO_LEGAL = "to-legal",
  CLOSE = "close",
  CS_NOTIFY = "cs-notify",
}

export interface AppActionParams {
  notes?: string;
  isAmend?: boolean;
  amendNotes?: string;
  isContractEdit?: boolean;
  editNotes?: string;
  isContractError?: boolean;
  errorNotes?: string;
  accrual?: boolean;
  since?: string;
  appliedDate?: string;
  defaulted?: boolean;
  restructured?: boolean;
  paidInFull?: boolean;
  paidEpo?: boolean;
  settlement?: boolean;
  writtenOff?: boolean;
  sold?: boolean;
  ignore?: boolean;
  returned?: boolean;
  emailTemplate?: string;
}

export enum Pipeline {
  LEADS = "leads",
  SCRUB = "scrub",
  INTAKE = "intake",
  PRIME = "prime",
  SELECT = "select",
  REP = "rep",
  CONTRACT = "contract",
  FUNDING = "funding",
  FINAL_REVIEW = "final review",
  ACTIVATION = "activation",
}

export interface Epo {
  days: number;
  rate: number;
}

export interface IsoCommLevel {
  isoCommission: number;
  buyRateAdjustment: number;
  epoGroup: Epo[];
}

export interface Guarantor {
  legalName: string;
  entityType?: "corporation" | "company" | "partnership";
  owners: { name: string; email: string }[];
}

export interface BankAccount {
  _id?: string;
  alias?: string;
  name: string;
  nameOnAccount: string;
  routing: string;
  account: string;
  frequency?: "daily" | "weekly";
  ffc?: string;
  notes?: string;
}

export interface Recipient {
  email?: string;
  emails?: { value: string; notes?: string }[];
  name: string;
  status?: string;
  desc?: string;
  kba?: boolean;
  type?: string;
}

export interface Signer extends Recipient {
  tabs: string[];
}

export interface SyndicationAccount {
  _id?: string;
  alias: string;
  balance: number;
  balanceFrozen: number;
  managementFeeRate: number;
  managementFeeBase: "on principal" | "on rtr";
  managementFeeTiming: "upfront" | "backend";
  commissionBase: "on principal" | "on rtr";
  commissionTiming: "upfront" | "backend";
  syndicationType: "across-the-board" | "pay-as-you-go";
  syndicationRate: number;
  minSyndicationAmount: number;
  maxSyndicationAmount: number | null;
  bankAccount?: string;
  lender?: string;
  tcpPayableByWallet?: boolean;
  payoutToWallet: boolean;
  autoWithdraw: boolean;
  isEnabled: boolean;
  eligibleToSyndIsoCommClawback: boolean;
  eligibleToSyndRepCommClawback: boolean;
  eligibleToSyndVbTrueUps: boolean;
  eligibleToVbClawback: boolean;
  portfolioCriterion: {
    item?: "funded amount" | "fico score";
    min?: number;
    max?: number;
  }[];
}

export enum PaymentType {
  SYNDICATOR_DEPOSIT = "sd", // Syndicator -> House
  SYNDICATOR_WITHDRAW = "sw", // House -> Syndicator
  SYNDICATOR_ACCOUNT_TRANSFER = "st", // Syndicator -> Syndicator
  SYNDICATION = "s", // Syndicator -> Advance
  SYNDICATION_FEE = "sf", // Syndicator -> House
  SYNDICATION_ISO_COMMISSION = "sic", // Syndicator -> House
  SYNDICATION_REP_COMMISSION = "src",
  MERCHANT_FUND = "f", // Advance -> Merchant
  MERCHANT_PAYBACK = "mp", // Merchant -> Advance
  ORIGINATING_FEE = "of", // Merchant -> House
  UNDERWRITING_FEE = "uf", // Merchant -> House
  TECH_FEE = "tf", // Merchant -> House
  POST_FUNDING_FEE = "pf", // Merchant / ISO -> Advance
  SYNDICATION_PAYOUT = "sp", // Advance -> Syndicator
  ISO_COMM = "ic", // House -> Iso
  ISO_FEE = "if", // Advance -> Iso
  REP_COMM = "rc", // Advance -> Rep
  REP_FEE = "rf", // Advance -> Rep
  THIRD_PARTY_LEGAL_CHARGE = "3lc",
  SYNDICATION_THIRD_PARTY_LEGAL_CHARGE = "s3lc", // Syndicator -> Lender
  THIRD_PARTY_PAYOUT = "3p",
  SYNDICATION_THIRD_PARTY_PAYOUT = "s3p",
  ISO_COMM_CLAWBACK = "icc",
  SYNDICATION_ISO_COMM_CLAWBACK = "sicc",
  REP_COMM_CLAWBACK = "rcc",
  SYNDICATION_REP_COMM_CLAWBACK = "srcc",
  VB = "vb",
  VB_CLAWBACK = "vbc",
  SYNDICATION_VB_CLAWBACK = "svbc",
  SYNDICATION_VB_TRUE_UPS = "svt",
  VB_RESERVE = "vr",
}

export enum PaymentState {
  PLANNED = "planned",
  PENDING = "pending",
  PAID = "paid",
  FAILED = "failed",
  CANCELED = "canceled",
}

export enum PaymentMethod {
  BALANCE = "balance",
  ACH = "ach",
  CHECH = "check",
  WIRE = "wire",
}

export enum FeeType {
  NSF = "nsf",
  DEFAULT = "default",
  BANK_CHANGE = "bank change",
  STACKING = "stacking",
  WIRE = "wire",
  MODIFICATION = "modification",
  PAY_OFF_LETTER = "pay off letter",
  LEGAL_BILLING = "legal billing",
  AD_HOC = "ad-hoc",
}

export enum StageType {
  "daily payments" = "daily",
  "weekly payments" = "weekly",
  "week-day holds" = "hold",
}

export enum StageLogic {
  DATE_BASED = "date",
  NPAY_BASED = "npay",
  FILL_REMAINING = "fill",
}

export interface PaymentGeneratorStage {
  type: StageType;
  logic: StageLogic;
  numberOfPayments?: number;
  individualPayment?: number;
  doubleDebit?: boolean;
  weekday?: number;
  till?: string;
  disabled?: boolean;
}

export enum NoteLabel {
  SCRUBBER = "scrubber",
  INTAKE = "intake",
  UW = "uw",
  ISO = "iso",
  REP = "rep",
  FINAL_REVIEW = "final review",
  CUSTOMER_SERVICE = "customer service",
  CONTRACT = "contract",
  TREASURY = "treasury",
  ACCOUNTING = "accounting",
}

export interface Note {
  label: NoteLabel;
  createdAt?: Date;
  message: string;
  createdBy?: User;
}

export interface DeclineReason {
  _id?: string;
  id: string;
  title: string;
  internalMessage: string;
  externalMessage: string;
  highlighted?: boolean;
  pipeline?: string;
  uwer?: User;
}

export type ListFilterType =
  | "text"
  | "date"
  | "number"
  | "boolean"
  | "set"
  | "user"
  | "application"
  | "iso"
  | "business"
  | "syndicator"
  | "payment";

export interface ListFilterDefinition {
  altKey?: string;
  type: ListFilterType;
  format?: string;
  altTypes?: ListFilterType[];
  refKey?: string;
  items?: { value: string | number; text: string }[];
  searchConditions?: Record<string, string>;
  delimiterSearch?: boolean;
  sortByKey?: string;
  empty?: boolean;
  emptyLabel?: string;
}

export interface ListFilter {
  key: string;
  altKey?: string;
  type: ListFilterType;
  altTypes?: ListFilterType[];
  refKey?: string;
  logic?: "AND" | "OR";
  exclude?: boolean;
  // text filters
  includes?: string;
  startWith?: string;
  endWith?: string;
  // date filters
  compareDateTime?: boolean;
  lastNDays?: number;
  daySpan?: number;
  dayDelta?: number;
  weekDelta?: number;
  monthDelta?: number;
  quarterDelta?: number;
  // number/date filters
  gt?: number;
  gte?: number;
  eq?: number;
  ne?: number;
  lt?: number;
  lte?: number;
  range?: number[];
  // boolean filters
  is?: boolean;
  // set filters
  items?: Array<string | number>;
  // document match
  refId?: string;
  // misc filters
  isMe?: boolean;
}

export type ListFilterNumberCondition =
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "eq"
  | "ne"
  | "range";

export type ListFilterDateCondition =
  | "gt"
  | "lt"
  | "eq"
  | "dayDelta"
  | "daySpan"
  | "weekDelta"
  | "monthDelta"
  | "quarterDelta";

export interface ListFilterCondition {
  value: ListFilterDateCondition | ListFilterNumberCondition;
  text: string;
  inputType: "date" | "number" | "none";
}

export interface FraudSignal {
  displayName: string;
  file: File;
}

export interface BankStatementSummary {
  aggregateByMonth: {
    month: string;
    estimatedRevenue: string;
    nsfCount: number;
    negativeBalancesDatesCount: number;
    averageDailyBalance: string;
    depositsCount: number;
    holdback: string;
  }[];
  bankAccounts: BankAccountInSummary[];
  fraudSignals?: FraudSignal[];
}

export type BankStatementMonthSummaryMtd = Omit<BankStatementMonth, "month">;

export interface BankAccountInSummary {
  name: string;
  accountHolder?: string;
  holderCountry?: string;
  holderState?: string;
  holderCity?: string;
  holderZip?: string;
  holderAddress1?: string;
  holderAddress2?: string;
  bankName: string;
  accountType?: string;
  accountCategory?: string;
  accountNumber: string;
  periods: BankStatementMonthSummary[];
  mtd?: BankStatementMonthSummaryMtd;
  label?: string;
}

export interface BankStatementMonth {
  month?: string;
  include?: boolean;
  notes?: string;
  beginBalance?: string;
  endBalance?: string;
  average?: string;
  averageDeposit?: string;
  averageDailyBalance?: string;
  depositsCount?: number;
  negativeBalancesDatesCount?: number;
  estimatedRevenue?: string;
  nsfCount?: number;
  suspiciousActivity?: string;
  transferOutAmount?: number;
  transferInAmount?: number;
  nsfPaidItems?: number;
  nsfReturnedItems?: number;
}

export interface BankStatementMonthSummary extends BankStatementMonth {
  original?: BankStatementMonthSummary;
}

export type Frequency =
  | "daily"
  | "semi-weekly"
  | "weekly"
  | "bi-weekly"
  | "monthly";

export interface PositionDetail {
  position: string;
  account: string;
  funderName: string;
  fundedDate: string;
  fundedAmount: number;
  payment: number;
  frequency: "daily" | "semi-weekly" | "weekly" | "bi-weekly" | "monthly";
  currentBalance: number;
  includeInWithhold: boolean;
  buyout: boolean;
}

export interface Contact {
  type: string;
  value: string;
  notes?: string;
  required?: boolean;
}

export interface PeerReview {
  role: "preparer" | "reviewer";
  item: string;
  check: boolean;
  user: User;
  time: Date;
}

export interface EntityLink {
  business?: string;
  iso?: string;
  syndicator?: string;
  receivesOc?: boolean;
  receivesRenewalOc?: boolean;
  receivesMissedPayments?: boolean;
  receivesFundingNotice?: boolean;
  isPrimary?: boolean;
  isOwner?: boolean;
}

export enum Role {
  ADMIN = "admin",
  ISO = "iso",
  UWER = "uwer",
  SCRUBBER = "scrubber",
  BUSINESS = "business",
  REP = "rep",
  DRAFTER = "drafter",
  ACCOUNTING = "accounting",
  TREASURY = "treasury",
  FINAL_REVIEWER = "final reviewer",
  CUSTOMER_SERVICE = "customer service",
  SYNDICATOR = "syndicator",
  AUDITOR = "auditor",
  VISITOR = "visitor",
}

export const externalRoles = [
  Role.BUSINESS,
  Role.ISO,
  Role.SYNDICATOR,
  Role.VISITOR,
  Role.AUDITOR,
];

export const internalRoles = Object.values(Role).filter(
  (r) => !externalRoles.includes(r)
);

export interface CustomerServiceTaskTemplate {
  name: string;
  statuses: string[];
}

export enum TaskType {
  BOUNCE__SOFT = "bounce - soft",
  BOUNCE__HARD = "bounce - hard",
  REP_REQUEST = "rep request",
  ISO_REQUEST = "iso request",
  MERCHANT_REQUEST = "merchant request",
  LEGAL_REQUEST = "legal request",
  TRIPARTY_AGREEMENT_REQUEST = "triparty agreement request",
  CS_PORTFOLIO_MONITORING = "cs portfolio monitoring",
  OTHER = "other",
}

export enum TaskStatus {
  PENDING_CS = "Pending CS",
  PENDING_TREASURY_DRAFT = "Pending Treasury Draft",
  PENDING_FINAL_REVIEW = "Pending Final Review",
  IN_PROCESS = "In Process",
  PENDING_TREASURY_REVIEW = "Pending Treasury Review",
  PENDING_CS__RECOMMENDATION_SENT = "Pending CS (Recommendation Sent)",
  PENDING_SIGNATURE = "Pending Signature",
  READY_TO_SEND = "Ready to Send",
  CS_DECISIONED = "CS Decisioned",
  SENT_TO_TREASURY = "Sent to Treasury",
  SCHEDULED = "Scheduled",
  SCHEDULE_FOLLOW_UP = "Schedule Follow Up",
  ON_HOLD__FOLLOW_UP_REQUIRED = "On Hold - Follow Up Required",
  MODIFICATION_DENIED = "Modification Denied",
  WAIVED = "Waived",
  COMPLETED = "Completed",
}

export interface EmailNotifyTemplate {
  name: string;
  subject: string;
  content: string;
}

export interface BounceType {
  code: string;
  desc: string;
  type: "soft" | "hard";
  generateEmail: boolean;
  createNsfFee: boolean;
}

export interface RepVolMtd {
  rep: string;
  repName: string;
  vol: number;
}

export class UploadPaymentItem {
  serial?: number;
  waived?: boolean;
  new?: boolean;
  set!: SetPayment;
  prev?: SetPayment;
  errorMessage?: string;
}

export interface SetPayment {
  type?: PaymentType;
  amount?: number;
  effectiveDate?: Date;
  appliedDate?: Date;
  state?: PaymentState;
  bounceCode?: string;
  transactionNo?: string;
  appCd?: string;
  method?: PaymentMethod;
  from?: string;
  to?: string;
}

export interface ScheduledPayment {
  application: string;
  no: number;
  date: Date;
  expected: number;
  state?: "plan" | "debiting" | "debited" | "paid" | "bounced";
  attempt?: number;
  fee?: number;
}

export interface ExpectedPostFundingFee {
  amount?: number;
  type?: FeeType;
  title?: "";
  date?: Date;
  serial?: number;
  waivedAt?: Date;
  waivedByUser?: string;
  waivedByUserFullName?: string;
}

/* Models */
export interface Model {
  _id?: string;
  id?: string;
  __v?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export class CModel implements Model {
  _id?: string;
  id!: string;
  __v?: number;
  createdAt!: Date;
  updatedAt!: Date;
}

export type Populated<T> = T | null;
type Ref<T> = string | undefined | T;

export interface Application extends Model {
  serial: string;
  lender: string;
  identity: string;
  preferredRiskSyndication: number;
  capitalTentative: number;
  originalReceiptDate: string;
  originalReceiptFrom: string;
  useOfCapital: string;
  stageHistory: string;
  stateHistory: string;
  priority: "high" | "normal";
  pipeline?: Pipeline;
  subPipeline?: Pipeline;
  shop: "prime" | "select";
  isServicing: boolean;
  businessAsOf: Omit<Business, "currentOwnership"> & {
    currentOwnership: (Omit<Ownership, "user"> & { user: string })[];
  };
  businessUsersAsOf: User[];
  dba?: string;
  iso: Iso | null;
  cbrDocs: string[];
  otherAppDocs: File[];
  ocrolusBookId?: number;
  ocrolusBookUuid?: string;
  bankStatementSummary?: BankStatementSummary;
  positionDetails?: PositionDetail[];
  chosenPlan?: Offer;
  initialSchedule?: ScheduledPayment[];
  postFundingFees?: ExpectedPostFundingFee[];
  tentativeOffer?: Offer;
  status: AppStatus;
  condition: AppCondition;
  tasks: Record<
    string,
    { state: string; condition?: string; updatedBy?: string }
  >;
  tags: string[];
  createdBy: Populated<User>;
  lastModifiedBy: Populated<User>;
  assignees: User[];
  contractResearcher?: Populated<User>;
  contractDrafter?: Populated<User>;
  fundingPreparer?: Populated<User>;
  fundingReviewer?: Populated<User>;
  modRequests: ModRequest[];
  currentModRequest?: ModRequest;
  contract?: Contract;
  syndications: Syndication[];
  rtrOutstanding?: number;
  notes: Note[];
  peerReviews: PeerReview[];
  // contractReadyDoubleCheckedAt?: Date;
  // contractReadyDoubleCheckedBy?: User;
  // voidCheckReadyDoubleCheckAt?: Date;
  // voidCheckReadyDoubleCheckBy?: User;
  // readyDoubleCheck?: Boolean;
  offlineFundedAmount?: number;
  offlineFundedDate?: Date;
  activeFinalReviewTask?: string;
  declineReasons?: DeclineReason[];
  accrual: boolean;
  accrualStates: { accrual: boolean; since: string }[];
  currentAccrualStateSince?: string;
  financials?: Record<string, number>;
  postFundingFeeConfig?: { type: FeeType; amount?: number; preNotes: string }[];
  repCommPayAs?: "new" | "renewal";
  advanceType?: "new" | "renewal";
  debitFromAccounts?: BankAccount[];
  // payment break-down fields
  periodAmount?: Record<string, number>;
  totalPrincipalPaid?: number;
  totalInterestPaid?: number;
  startingBalance?: number;
  endingBalance?: number;
  accruedBalance?: number;
  mig?: string;
  lastMigratedMerchantPaymentDate?: Date;
  currentPaymentAmount?: number;
  pendingMerchantPaybackPayments?: number;
  bounceAmount?: number;
  bounceCount?: number;
  feeOutstanding?: number;
  feePaidBack?: number;
  rtrPerformance?: number;
  activatedAt?: Date;
  bounceFeeOutstandingAmount?: number;
  arrears?: number;
  is100Syndication?: boolean;
}

export interface BankStatementAnalytics extends Model {
  application: string;
  categories: string[];
  transactions: Record<string, any>[];
  dailyBalances: { date: string; amount: number }[];
}

export interface Business extends Entity {
  foundedAt?: Date;
  businessStartDate?: string;
  dbas: Dba[];
  ownershipDate?: string;
  sicCode?: string;
  cbrVariablesHist?: {
    ccStore: string;
    dnr30: string;
  };
  revenueVariablesHist?: {
    revMo: string;
    withholdMo: string;
    position: number;
  };
  currentServingIso?: Partial<Iso> | null;
  currentOwnership: Ownership[];
  industry?: Industry;
  lender: string;
  isServicing: boolean;
}

export interface ConfigItem extends Model, Record<string, any> {
  desc: string;
  key: string;
  value: any;
}

export interface Contract extends Model {
  application: string;
  type: "mca" | "lsa" | null;
  entityOtherIdentity?: string;
  verifiedFields: string[];
  guarantors: Guarantor[];
  primaryBank?: BankAccount;
  secondaryBank?: BankAccount;
  achBankAccounts: BankAccount[];
  contractPath?: string;
  envelopeId?: string;
  sent?: boolean;
  recipients: Signer[];
  carbonCopies: Recipient[];
}

export interface InformationalMessage {
  messageNumber: string;
  messageNumberDetailed: string;
  messageText: string;
}

export interface ScoreFactor {
  importance: string;
  code: string;
}

export interface RiskModel {
  evaluation: string;
  modelIndicator: string;
  score: string;
  scoreFactors: ScoreFactor[];
}

export interface ConsumerCreditProfile extends Model {
  user?: string;
  addressInformation: any[];
  consumerIdentity: any;
  employmentInformation: any[];
  inquiry: any[];
  summaries: any[];
  riskModel: RiskModel[];
  ssn: any[];
  tradeline: any[];
  informationalMessage?: InformationalMessage[];
}

export interface CustomerServiceTask extends Model {
  advance?: string;
  name: string;
  type?: TaskType;
  status?: TaskStatus;
  desc: string;
  role?: Role;
  assignee?: User;
}

export interface Entity extends Model {
  officialName: string;
  dba?: string;
  fein?: string;
  bankAccounts: BankAccount[];
  currentBizPhysicalAddress?: Partial<Address>;
  currentBizMailingAddress?: Partial<Address>;
  contacts: { type: string; value: string; notes?: string }[];
  blacklist: BlackListStatus[];
  entityType?: string;
  tags: string[];
  coopSince?: Date;
  incorporateStates?: string[];
  website?: string;
  docs: File[];
}

export interface File extends Model {
  uri: string;
  thumbnailUrl?: string;
  name: string;
  type: string;
  size: number;
  stipLabel?: string;
  stipStatus?: "pending" | "approved" | "declined";
  uploadedUser: Populated<User>;
  status: "good" | "fraud" | "none";
  ocrStatus: string;
  uploadedOnAppCondition?: AppCondition;
}

export interface Industry extends Model {
  n: string;
  name: string;
  type: number;
  cat?: string;
}

export interface IndustryTreeNode extends Industry {
  children?: IndustryTreeNode[];
}

export interface Iso extends Entity {
  contacts: Contact[];
  parentIso: Ref<Iso>;
  currentContacts: Partial<User>[];
  currentReps: Partial<User>[];
  assignedRepGroupHist?: string;
  introducedBy?: Ref<User>;
  priority: "high" | "normal";
  backgroundCheckStatus: "Pending" | "Passed" | "Failed";
  isActive: boolean;
  isServicing: boolean;
  is1099Required: boolean;
}

export interface ListTemplate extends Model {
  name: string;
  target: string;
  pipeline?: string;
  actions?: string[];
  headers: string[];
  filters: ListFilter[];
  query?: Record<string, string>;
  sortBy: string[];
  sortDesc: boolean[];
  isPrivate?: boolean;
  sharedToRoles?: Role[];
  sharedToUsers?: User[];
  createdBy?: Populated<User>;
  isFixed?: boolean;
  count?: number;
  isTemp?: boolean;
}

export interface Log extends Model {
  user: string;
  ip: string;
  ua: string;
  title: string;
  desc: string;
  data?: Record<string, any>;
  business?: string;
  iso?: string;
  syndicator?: string;
  application?: string;
  payment?: string;
}

export interface Notification extends Model {
  to: Ref<User>;
  type: string;
  title: string;
  desc: string;
  emails?: string[];
  isRead: boolean;
  isDone: boolean;
  link?: string;
}

export interface Syndicator extends Entity {
  isRealPerson: boolean;
  currentContacts: Partial<User>[];
  requires1099TaxReporting: boolean;
  allowOverPayback: boolean;
  voidCheck: File;
  w9: File;
  accounts: SyndicationAccount[];
}

export interface SyndicationReport {
  syndicatedCount: number;
  syndicatedAmount: number;
  commissionExpectedAmount?: number;
  managementFeeExpectedAmount?: number;
  rtr: number;
  appStatus: number;
  appCondition: string;
  cafExpectedAmount: number;
  factor: number;
  cafPaidAmount: number;
  tcpPaidAmount: number;
  vbCostAmount: number;
  clawBacksAmount: number;
  thirdPartyLegalChargeAmount: number;
  thirdPartyPayoutAmount?: number;
  paidOutAmount?: number;
  pendingAmount: number;
  tcp: number;
  paidBackAmount: number;
  percentage: number;
}

export interface Payment extends Model {
  type: PaymentType;
  state: PaymentState;
  no?: string;
  title?: string;
  amount: number;
  amountOriginal?: number;
  amountPrev?: number;
  effectiveDate?: Date;
  appliedDate?: Date;
  method?: PaymentMethod;
  gatewayState?: string;
  failReason?: string;
  advance?: Ref<Application> | Populated<Application>;
  merchant?: Ref<Business>;
  iso?: Ref<Iso>;
  syndicator?: Ref<Syndicator>;
  account?: string;
  houseAccount?: string;
  transferToSyndicator?: Ref<Syndicator>;
  transferToAccount?: string;
  feeType?: FeeType;
  canceledAt?: Date;
  transactionNo?: string;
}

export interface Post extends Model {
  title: string;
  slug?: string;
  content: string;
  tags: string[];
  posterUrl: string | null;
  target?: string;
  author: Populated<User>;
}

export interface Product extends Model {
  portfolio: string;
  name: string;
  original: Record<string, Record<string, number>>;
  maxIsoCommission: number;
  expiredAt: number;
  levels: IsoCommLevel[];
  alias?: string;
}

export interface UploadActivity extends Model {
  serial?: number;
  status: "parsing" | "ready" | "applied" | "failed";
  uploadedBy?: User;
  file?: File;
  errorMessage?: string;
  itemsProcessed?: number;
  items: UploadPaymentItem[];
  prevs?: UploadPaymentItem[];
}

export interface User extends Model {
  role: Role;
  fullName: string;
  firstName: string;
  middleName?: string;
  lastName?: string;
  namePrefix?: string;
  nameSuffix?: string;
  login?: string;
  password?: string;
  passwordExpiresAt?: Date;
  currentPhone?: string;
  currentEmail?: string;
  contacts: Contact[];
  currentPhysicalAddress?: Partial<Address>;
  currentMailingAddress?: Partial<Address>;
  socialNo?: string;
  dateOfBirth?: string;
  avatarUrl?: string;
  iso?: string;
  business?: string;
  favoriteListTemplates: ListTemplate[];
  authorizedPipelines: string[];
  authorizedJobs: string[];
  defaultPortfolio: string;
  isManager: boolean;
  disabled: boolean;
  recentIps: string[];
  attemptIps: string[];
  authorizedIps: { ip: string; date: Date }[];
  entityLinks: EntityLink[];
  tags: string[];
  loginAttempts?: number;
  application?: string;
  can: (cap: string) => boolean;
}

export type AuthUser = Pick<
  User,
  "role" | "passwordExpiresAt" | "tags" | "login" | "isManager"
> | null;

export interface AuthLoginPostBody {
  login: string;
  password: string;
}

export interface AuthLoginResponseBody {
  token: string;
  user: User;
}

export interface ListQuery {
  order?: string;
  limit?: number;
  skip?: number;
}

export interface ListResponseBase {
  $headers: Record<string, string>;
}

export interface AuthTokenUserIdResponseBody extends AuthLoginResponseBody {}

export interface ApplicationPostBody extends Application {}

export interface ApplicationPutBody extends Application {}

export interface ApplicationPostQuery {}

export interface ApplicationQuery extends ListQuery {
  status?: string | string[]; // support comma separated values
  customerKeyword?: string;
  type?: string;
  store?: string;
  date?: string;
  customer?: string;
  event?: string;
  gift?: string;
  coupon?: string;
}

export interface LogQuery extends ListQuery {
  syndicator?: string;
  iso?: string;
  business?: string;
  payment?: string;
  application?: string;
}

export interface UserPostBody extends User {}

export interface UserPutBody extends User {}

export interface UserQuery extends ListQuery {}
